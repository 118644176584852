import * as React from 'react';
import { 
	AppBar,
	Box,
	Button,
	Grid,
	IconButton,
	Toolbar,
	Typography,
} from '@mui/material';
import { 
	Facebook,
	Instagram,
	LinkedIn,
} from '@mui/icons-material';
import LogoDowinWhite from "images/logodowinWhite.png";
import { navigate } from "gatsby"


export default function PapFooter(props) {
    const whiteTextButton = {
        fontFamily: 'Quicksand',
        fontSize: props.isMobile ? '12px': '24px',
        lineHeight: props.isMobile ? '14px': '30px',
        fontWeight: '500',
        color: '#FFFFFF',
    }
    const whiteText = {
        fontFamily: 'Quicksand',
        fontSize: props.isMobile ? '12px' : '24px',
        lineHeight: props.isMobile ? '14px':'30px',
        fontWeight: '400',
        color: '#FFFFFF',
    }
    const footerStyles = {
        minWidth: props.isMobile ? 'auto' : '1440px',
        height: props.isMobile ? 'auto': '266px',
        backgroundColor: '#000000',
    }
    const footerContainer = {
        paddingLeft: props.isMobile ? '35px' : '180px',
        paddingTop: props.isMobile ? '25px' : '60px',
        paddingRight: props.isMobile ? '15px' : '70px',
        paddingBottom: props.isMobile ? '25px' : '60px',
    }
    const noTextDecoration = {
        textDecoration: 'none',
    }
    const imgResize = {
        maxWidth: props.isMobile ? '100px' : 'auto',
        height: 'auto',
    }
    
    return (
        <AppBar style={footerStyles} position={'relative'} sx={{ top: 'auto', bottom: 0 }}>
            <Toolbar style={footerContainer}>
                <Grid container spacing={2}>
                    <Grid item xs={4} md={8}>
                    <img
                        alt="dowin Logo white"
                        style={imgResize}
                        src={LogoDowinWhite}
                    />
                    </Grid>
                    <Grid item alignSelf={'center'} xs={2} md={1}></Grid>
                    <Grid item alignSelf={'center'} xs={6} md={3}>
                        <a href='https://www.instagram.com/dowin_app/'>
                            <IconButton aria-label="instagram" size="large">
                                <Instagram fontSize="inherit" style={{fill: '#FFFFFF'}}/>
                            </IconButton>
                        </a>
                        <a href='https://www.facebook.com/profile.php?id=100076416115388'>
                            <IconButton aria-label="facebook" size="large">
                                <Facebook fontSize="inherit" style={{fill: '#FFFFFF'}}/>
                            </IconButton>
                        </a>
                        <a href='https://www.linkedin.com/company/premiumandpremium/'>
                            <IconButton aria-label="linkedin" size="large">
                                <LinkedIn fontSize="inherit" style={{fill: '#FFFFFF'}}/>
                            </IconButton>
                        </a>
                    </Grid>
                    <Grid item xs={12}>
                        <a style={noTextDecoration} href='https://firebasestorage.googleapis.com/v0/b/premiumandpremium.appspot.com/o/documents%2FprivateDocuments%2FAviso%20Legal%20PP_revisado.pdf?alt=media&token=cd5b9132-36a2-434a-864b-663d95d7518d'>
                            <Button style={whiteTextButton}>Aviso Legal</Button>
                        </a>
                        <a style={noTextDecoration} href='https://firebasestorage.googleapis.com/v0/b/premiumandpremium.appspot.com/o/documents%2FprivateDocuments%2FPolitica%20de%20Privacidad%20PP_revisado.pdf?alt=media&token=1644c9bb-90d9-453d-98fc-52449956868c'>
                            <Button style={whiteTextButton}>Política de privacidad</Button>
                        </a>
                        <a  style={noTextDecoration} href='https://firebasestorage.googleapis.com/v0/b/premiumandpremium.appspot.com/o/documents%2FprivateDocuments%2FInformacion%20sobre%20el%20Uso%20de%20Cookies%20P%26P.pdf?alt=media&token=82f5eb74-c1b1-4606-846b-d89070e6bfc1'>
                            <Button style={whiteTextButton}>Política de cookies</Button>
                        </a>
                        <Button style={whiteTextButton} onClick={() => { navigate('/aboutUs') }}>Nosotros</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography style={whiteText}>© All rights reserved, Premium&Premium 2022</Typography>
                    </Grid>
                </Grid>
                <Box sx={{ flexGrow: 1 }} />
            </Toolbar>
        </AppBar>
    );
};